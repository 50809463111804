<template>
  <div class="page">
    <Navbar title="云课堂" type="SMP" />
    <div class="records">
      <div class="title"><van-icon name="notes-o" /> 我的课程</div>
      <div
        class="item"
        @click="room(course.code)"
        v-for="course in courseList"
        :key="course.code"
      >
        <van-row>
          <van-col span="8">
            <div class="image">
              <van-image radius="3" :src="course.imagePath"></van-image>
            </div>
          </van-col>
          <van-col span="16">
            <div class="name text-short">{{ course.name }}</div>
            <van-row class="count">
              <van-col span="12">课时：{{ course.count }}节</van-col>
              <van-col span="12">时长：{{ course.duration }}分钟</van-col>
            </van-row>
            <div class="time color-s1">有效期至：{{ course.timeEnd }}</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="own" />
    <Share ref="share" />
  </div>
</template>
<script>
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
  components: {
    Tabbar: Tabbar,
    Loading: Loading,
    Share: Share,
    Navbar: Navbar,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Image.name]: Image,
    [Cell.name]: Cell
  },
  data () {
    return {
      loadingShow: false,
      courseList: [],
      amount: 0,
      orderCode: '2',
      payOrderCode: '1'
    }
  },
  mounted () {
    this.retrieveCourseList()
    this.$refs.share.default('main')
  },
  methods: {
    async retrieveCourseList () {
      this.loadingShow = true
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/course/retrieveUserCourseList')
      if (res.status === '200') {
        this.courseList = res.data
      }
      this.loadingShow = false
    },
    room (code) {
      this.$router.push({ path: '/mde/train/courseRoom', query: { courseCode: code } })
    }
  }
}
</script>
<style lang="less" scoped>
.records {
  margin: 30px 2% 0px 2%;
  .title {
    height: 25px;
    line-height: 25px;
    border-bottom: 1px solid #eee;
    padding: 5px 10px;
    text-align: left;
    font-size: 15px;
    font-weight: 800;
  }
  .item {
    margin: 3px;
    border-radius: 3px;
    padding: 3px 0px;
    border-bottom: 1px dashed #eee;
    .name {
      padding: 0px 3px;
      text-align: left;
      font-size: 14px;
      font-weight: 700;
      margin: 3px 0px 4px 0px;
    }
    .count {
      padding: 0px 3px;
      text-align: left;
      margin: 4px 0px;
    }
    .time {
      text-align: left;
      padding: 0px 3px;
      margin: 4px 0px;
    }
  }
}
</style>
